<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="6">
        <b-card header="Channel selection">
          <h4 class="smalltitle">Welcome channel</h4>
          <hr />Useful variables:
          <br />
          <code>{mention}</code> - Mentions the person joining/leaving.
          <br />
          <code>{server}</code> - The server's name
          <br />
          <code>{user(proper)}</code> - The person joining/leaving's name in the 'Carl#0001' format.
          <br />
          <code>{server(members)}</code> - The total number of members after the event has happened.
          <br />
          <a href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage">Tagscript</a> is partially supported, use
          <code>{random: first message ~ second message}</code> for a randomized message (separated by ~) or
          <code>{ord: {server(members)}}</code> to transform
          <code>8102</code> to
          <code>8,102nd</code>
          <hr />

          <label for="discord_channel_select" class="teenytinytitle">Welcome Channel</label>
          <multiselect
            v-model="greet_channel"
            id="discord_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="groupedChannels"
            :allow-empty="true"
            group-values="channels"
            group-label="category"
            :group-select="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color:#72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>

          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
          </div>
        </b-card>
        <b-card>
          <div slot="header">
            <i class="icon-logout"></i> Goodbye Message
          </div>
          <h5 class="smalltitle">Leave message</h5>
          <hr />
          <b-input-group>
            <b-form-textarea
              :maxlength="5000"
              rows="6"
              type="text"
              placeholder="It seems {user} has left us... We are now {server(members)}"
              class="col-12"
              v-model="farewell_message"
            ></b-form-textarea>
          </b-input-group>
          <EmbedBuilder ref="farewell_embed"></EmbedBuilder>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
            <b-button variant="ghost-danger" class="ml-2" v-on:click="farewell_message = ''">Clear</b-button>
          </div>
        </b-card>
        <b-card>
          <div slot="header">
            <i class="icon-shield"></i> Ban Message
          </div>
          <h5 class="smalltitle">
            Ban message
            <font-awesome-icon
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
              id="restricted_question"
            />
            <b-popover triggers="hover" placement="top" target="restricted_question">
              <h5 class="tinytitle">What is a ban message?</h5>It is
              <i>not</i> a message that gets sent to the banned member. It is a message sent to the predetermined channel when a ban occurs.
            </b-popover>
          </h5>
          <hr />
          <b-input-group>
            <b-form-textarea
              :maxlength="5000"
              rows="6"
              type="text"
              placeholder="{user} flew too close to the sun and got banned. Owned."
              class="col-12"
              v-model="ban_message"
            ></b-form-textarea>
          </b-input-group>
          <EmbedBuilder ref="ban_embed"></EmbedBuilder>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
            <b-button variant="ghost-danger" class="ml-2" v-on:click="ban_message = ''">Clear</b-button>
          </div>
        </b-card>
      </b-col>
      <b-col sm="12" md="6">
        <b-card>
          <div slot="header">
            <i class="icon-login"></i> Welcome Message
          </div>
          <h5 class="smalltitle">Welcome message</h5>
          <hr />
          <b-input-group>
            <b-form-textarea
              :maxlength="5000"
              :rows="6"
              type="text"
              placeholder="Hello {mention} and welcome to {server}."
              class="col-12"
              v-model="greet_message"
            ></b-form-textarea>
          </b-input-group>
          <EmbedBuilder ref="greet_embed"></EmbedBuilder>

          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
            <b-button variant="ghost-danger" class="ml-2" v-on:click="greet_message = ''">Clear</b-button>
          </div>
        </b-card>
        <b-card>
          <div slot="header">
            <i class="icon-envelope"></i> DM on join message
          </div>
          <h5 class="smalltitle">DM on join</h5>
          <hr />
          <b-input-group>
            <b-form-textarea
              :maxlength="2000"
              rows="6"
              type="text"
              placeholder="Howdy {user} and welcome to {server}! Make sure to check out #rules before gaining access to the server."
              class="col-12"
              v-model="dm_message"
            ></b-form-textarea>
          </b-input-group>
          <EmbedBuilder ref="dm_embed"></EmbedBuilder>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings">Save</b-button>
            <b-button variant="ghost-danger" class="ml-2" v-on:click="dm_message = ''">Clear</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import EmbedBuilder from "@/views/oc/EmbedBuilder";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

Vue.use(VueNotifications, toast_options);

export default {
  name: "Logging",
  components: {
    cSwitch,
    EmbedBuilder,
    Multiselect
  },
  data: function() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      channels: [],
      greet_channel: null,
      greet_message: "",
      greet_embed: {},
      farewell_message: "",
      farewell_embed: {},
      dm_message: "",
      dm_embed: {},
      ban_message: "",
      ban_embed: {}
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/welcome")
      .then(r => {
        this.channels = r.data.channels;
        this.greet_channel = this.channels.find(
          x => x.id == r.data.greet_channel
        ) || { name: "No channel selected", id: null };
        this.greet_message = r.data.greet_message;
        this.farewell_message = r.data.farewell_message;
        this.dm_message = r.data.dm_message;
        this.ban_message = r.data.ban_message;
        this.$refs.greet_embed.apply_json(r.data.greet_embed);
        this.$refs.farewell_embed.apply_json(r.data.farewell_embed);
        this.$refs.ban_embed.apply_json(r.data.ban_embed);
        this.$refs.dm_embed.apply_json(r.data.dm_embed);
      });
  },
  methods: {
    put_settings() {
      this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/welcome", {
          greet_channel: this.greet_channel ? this.greet_channel.id : null,
          greet_message: this.greet_message,
          farewell_message: this.farewell_message,
          dm_message: this.dm_message,
          ban_message: this.ban_message,
          greet_embed: this.$refs.greet_embed.full_json,
          farewell_embed: this.$refs.farewell_embed.full_json,
          ban_embed: this.$refs.ban_embed.full_json,
          dm_embed: this.$refs.dm_embed.full_json
        })
        .then(this.showSuccessMsg());
    }
  },
  computed: {
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  },
  created() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/welcome")
      .then(r => {
        this.channels = r.data.channels;
        this.greet_channel = this.channels.find(
          x => x.id == r.data.greet_channel
        ) || { name: "No channel selected", id: null };
        this.greet_message = r.data.greet_message;
        this.farewell_message = r.data.farewell_message;
        this.dm_message = r.data.dm_message;
        this.ban_message = r.data.ban_message;
        this.$refs.greet_embed.apply_json(JSON.parse(r.data.greet_embed));
        this.$refs.farewell_embed.apply_json(JSON.parse(r.data.farewell_embed));
        this.$refs.ban_embed.apply_json(JSON.parse(r.data.ban_embed));
        this.$refs.dm_embed.apply_json(JSON.parse(r.data.dm_embed));
        this.$forceUpdate();
        this.$Progress.finish();
      });
  }
};
</script>

